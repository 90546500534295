import { useEffect, useRef, useState } from "react";
import { Cluster } from "../hooks/clusters";
import { useMarkers } from "../hooks/markers";
import { ReadByBoundData } from "../hooks/postgis";
import { isMobile } from "../utils/utils";
import LeafletMap from "./map/LeafletMap";
import SideBar from "./map/SideBar";
import useCreateMap from "../hooks/map";
import {
  initCord,
  initCordMobile,
  initZoom,
  maxZoom,
  minZoom,
} from "../configs/configs";

interface MyMapProps {
  updatedDate: string;
  fetchDate: () => void;
  sideBarRef: React.RefObject<HTMLDivElement>;
}

const MyMap: React.FC<MyMapProps> = ({
  updatedDate,
  fetchDate,
  sideBarRef,
}) => {
  const [selectedMarker, setSelectedMarker] = useState<ReadByBoundData | null>(
    null
  );
  // Create a map.
  const { map, isMoved } = useCreateMap("map", {
    center: isMobile() ? initCordMobile : initCord,
    zoom: initZoom,
    zoomControl: false,
    scrollWheelZoom: false,
    // tap: false,
    // dragging: !L.Browser.mobile,
  });
  // Create a cluster Reference.
  const clusterRef = useRef<Cluster | null>(null);
  // Create markers.
  const { markerList, updateMarkers } = useMarkers(map, setSelectedMarker);

  // SideBar controller
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(!isMobile());
  const toggleSideBar = () => {
    // Only for desktop.
    if (isMobile()) return;
    setIsSideBarOpen(!isSideBarOpen);
    if (sideBarRef.current) {
      sideBarRef.current.style.width =
        sideBarRef.current.offsetWidth === 0 ? "280px" : "0";
    }
  };
  const toggleSideBarMobile = () => {
    // Only for mobile.
    if (!isMobile()) return;
    if (!sideBarRef.current) return;
    // Scroll sidebar into view to open it.
    if (!isSideBarOpen) {
      sideBarRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    } else {
      // Scroll map into view to close it.
      map?.getContainer().scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
    setIsSideBarOpen(!isSideBarOpen);
  };

  const resizeMap = () => {
    if (map) {
      map.invalidateSize();
    }
  };

  const [total, setTotal] = useState<number>(0);

  // Add a mask for map when scrolling.
  const contentRef = useRef<HTMLDivElement>(null);
  const [isMapScrolling, setIsMapScrolling] = useState<boolean>(false);
  const isMouseOverMap = useRef<boolean>(false);
  const isCtrlPressed = useRef<boolean>(false);
  useEffect(() => {
    if (isMobile()) return;
    contentRef?.current?.addEventListener("mouseover", () => {
      isMouseOverMap.current = true;
    });
    contentRef?.current?.addEventListener("mouseout", () => {
      isMouseOverMap.current = false;
    });
    document?.addEventListener("keydown", (event) => {
      if (event.ctrlKey || event.metaKey || event.key === "Control") {
        isCtrlPressed.current = true;
      }
    });
    document?.addEventListener("keyup", () => {
      isCtrlPressed.current = false;
    });
    var wheelTimeout: any;
    contentRef?.current?.addEventListener("wheel", (event) => {
      let zoom = map?.getZoom();
      if (!zoom) return;
      // Zoom in/out
      if (isCtrlPressed.current) {
        event.preventDefault();
        if (event.deltaY < 0) {
          zoom += Math.min(event.deltaY * -0.25, 1);
        } else {
          zoom += Math.max(event.deltaY * -0.25, -1);
        }
        zoom = Math.min(Math.max(zoom, minZoom), maxZoom);
        if (zoom > maxZoom || zoom < minZoom) {
          return;
        }
        map?.setZoom(zoom);
        return;
      }

      // Show the mask
      if (isMouseOverMap.current && !isMapScrolling) {
        setIsMapScrolling(true);
        // Clear the previous timeout
        clearTimeout(wheelTimeout);
        // Set a new timeout to detect the end of scrolling
        wheelTimeout = setTimeout(function () {
          setIsMapScrolling(false);
        }, 1000);
      }
    });
  }, [map]);
  const maskElmt = (
    <div id="map-cover">
      <p className="map-cover-text">
        地図をズームするには、Ctrlキーを押しながらスクロールしててください。
      </p>
    </div>
  );
  const maskElmtMobile = (
    <div id="map-cover">
      <p className="map-cover-text">地図を移動させるには指2本で操作します。</p>
    </div>
  );

  if (isMobile()) {
    return (
      <div className="container-mobile">
        <div
          className="content mobile"
          ref={contentRef}
          // onTouchMove={(e) => {
          //   if (e.touches.length > 1) return;
          //   if (!isMapScrolling) setIsMapScrolling(true);
          // }}
          // onTouchEnd={() => {
          //   if (isMapScrolling) setIsMapScrolling(false);
          // }}
        >
          {/* {isMapScrolling && maskElmtMobile} */}
          <LeafletMap
            toggleSideBar={toggleSideBarMobile}
            isSideBarOpen={isSideBarOpen}
            map={map}
            isMoved={isMoved}
            markerList={markerList}
            updateMarkers={updateMarkers}
            clusterRef={clusterRef}
            setSelectedMarker={setSelectedMarker}
            updatedDate={updatedDate}
            fetchDate={fetchDate}
            total={total}
          />
        </div>
        <div
          className="sidebar-mobile"
          ref={sideBarRef}
          onTransitionEnd={resizeMap}
        >
          <SideBar
            isMoved={isMoved}
            map={map}
            markerList={markerList}
            toggleSideBarMobile={toggleSideBarMobile}
            updateMarkers={updateMarkers}
            selectedMarker={selectedMarker}
            setSelectedMarker={setSelectedMarker}
            setTotal={setTotal}
            total={total}
            isSideBarOpen={isSideBarOpen}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="container">
        <div
          className="sidebar"
          style={{ width: isSideBarOpen ? 280 : 0 }}
          ref={sideBarRef}
          onTransitionEnd={resizeMap}
        >
          <SideBar
            isMoved={isMoved}
            map={map}
            markerList={markerList}
            toggleSideBarMobile={toggleSideBarMobile}
            updateMarkers={updateMarkers}
            selectedMarker={selectedMarker}
            setSelectedMarker={setSelectedMarker}
            setTotal={setTotal}
            total={total}
            isSideBarOpen={isSideBarOpen}
          />
        </div>
        <div className="content" ref={contentRef}>
          {isMapScrolling && maskElmt}
          <LeafletMap
            toggleSideBar={toggleSideBar}
            isSideBarOpen={isSideBarOpen}
            map={map}
            isMoved={isMoved}
            markerList={markerList}
            updateMarkers={updateMarkers}
            clusterRef={clusterRef}
            setSelectedMarker={setSelectedMarker}
            updatedDate={updatedDate}
            fetchDate={fetchDate}
            total={total}
          />
        </div>
      </div>
    );
  }
};

export default MyMap;
